<template>

  <div class="cta_main_view w-100">

    <div class="view_inner d-flex flex-column">
      <div class="view_center basic_form">
        <div class="steps_dot mb-3">
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active"></span>
          <span class="dotted_circle"></span>
          <span class="dotted_circle" v-if="getCampaignAdd.campaign_type.retargeting"></span>
        </div>

        <div v-if="['exit_intent', 'bridge_cta'].includes(getCampaignAdd.cta_type)"
             class=" mb-3 exit-intent-toggle input_icon_text ">
          <div v-if="exitIntentToggle" class="toggle toggle-background-color"></div>
          <div v-if="!exitIntentToggle" class="toggle toggle-background-image"></div>
          <div class="d-flex justify-content-center align-items-center mt-1 mb-2">
            <div :class="{'active':exitIntentToggle}" @click="toggle(true)" class="url">
              <small>Background Color</small>
            </div>
            <div :class="{'active':!exitIntentToggle}" @click="toggle(false)" class="url ml-5">
              <small>Background Image</small>
            </div>
          </div>
          <div v-if="exitIntentToggle" class="content">
            <div class="name">
              <input v-model="getCampaignAdd.background_color"
                     type="color" name="" class="">
            </div>
          </div>
          <div v-if="!exitIntentToggle" class="content">
            <div class="name">
              <div class="validation_input">
                <input
                  id="custom_favicon_image"
                  placeholder="Enter the favicon image url (16 x 16 recommended)"
                  type="file"
                  @change="uploadExitIntentBackgroundImage($event)"
                  accept=".png, .jpg, .jpeg, .gif, .ico"
                  class="form-control txt-left-left d-none">
                <label for="custom_favicon_image" class="upload-meta-image">
                  <i v-if="!getCampaignLoaders.image_upload_exit_intent" class="fa fa-upload"
                     aria-hidden="true"></i>
                  <clip-loader v-if="getCampaignLoaders.image_upload_exit_intent" class="loader" :size="'14px'"
                               :color="'#2d99ec'"></clip-loader>
                  <span>Upload Image</span>
                </label>
              </div>
              <p class="recommended_text">Recommended size: (1920 x 1080)</p>
            </div>
          </div>

          <div class="d-flex align-items-center ">
            <div class="content mb-2">
              <div class="url">
                Opacity
              </div>
              <div class="name" v-tooltip="bridgeCtaOptionLockMessage(`Opacity can't be changed in type Bridge CTA.`)">
                <input type="range" :disabled="isBridgeCTACampaign()" min="0" max="1" v-tooltip="getCampaignAdd.exit_intent_opacity" value="1" v-model="getCampaignAdd.exit_intent_opacity" step="0.01">
              </div>
            </div>
          </div>
        </div>

        <div v-else class=" mb-3 input_icon_text d-flex align-items-center ">
          <div class="content">
            <div class="url">
              Background Color
            </div>
            <div class="name">
              <input v-model="getCampaignAdd.background_color"
                     type="color" name="" class="">
            </div>
          </div>
        </div>

        <div class=" mb-3 input_icon_text d-flex align-items-center ">
          <div class="content">
            <div class="url">
              Text Color
            </div>
            <div class="name">
              <input v-model="getCampaignAdd.text_color"
                     type="color" name="" class="">
            </div>
          </div>
        </div>


        <div v-if="getCampaignAdd.type !== 'link'" class=" mb-3 input_icon_text d-flex align-items-center ">
          <div class="content">
            <div class="url">
              Button Background Color
            </div>
            <div class="name">
              <input type="color"
                     v-model="getCampaignAdd.button_background_color"
                     name="" class="">
            </div>
          </div>
        </div>
        <div v-if="getCampaignAdd.type !== 'link'" class=" mb-3 input_icon_text d-flex align-items-center ">
          <div class="content">
            <div class="url">
              Button Text Color
            </div>
            <div class="name">
              <input v-model="getCampaignAdd.button_text_color"
                     type="color" name="" class="">
            </div>
          </div>
        </div>
        <div v-if="getCampaignAdd.type === 'link'" class=" mb-3 input_icon_text d-flex align-items-center ">
          <div class="content">
            <div class="url">
              Link Text Color
            </div>
            <div class="name">
              <input type="color" v-model="getCampaignAdd.link_color"
                     name="" class="">
            </div>
          </div>
        </div>
        <div v-if="!['exit_intent', 'popup_center', 'banner', 'bridge_cta'].includes(getCampaignAdd.cta_type)"
          class=" mb-3 input_icon_text d-flex align-items-center ">
          <div class="content">
            <div class="url">
              Position
            </div>
            <div class="name">
              <select v-model="getCampaignAdd.position">
                <option value="left">
                  Bottom Left
                </option>
                <option value="right">
                  Bottom Right
                </option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="['social_modern', 'social'].includes(getCampaignAdd.cta_type)"
             class=" mb-3 input_icon_text d-flex align-items-center ">
          <div class="content">
            <div class="url">
              Circle border
            </div>
            <div class="name">
              <select v-model="getCampaignAdd.circle">
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="!['bridge_cta'].includes(getCampaignAdd.cta_type)" class=" mb-3 input_icon_text d-flex align-items-center ">
          <div class="content">
            <div class="url">
              Popup sound
            </div>
            <div class="name">
              <select v-model="getCampaignAdd.sound">
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
        </div>

      </div>

      <div class="view_bottom">

        <div class="view_bottom_inner text-center">
          <button class="btn---cta light-blue btn-round" @click="$router.push({ 'name': 'message' })">
            <span>Go back</span>
          </button>

          <!-- if type is of retargeting -->
          <!-- removed this bellow button to set retargeting page at the end of while selecting both retarget and cta -->
          <!--                  <button @click="$router.push({ 'name': 'retargeting' })"-->
          <!--                          v-if="getCampaignAdd.campaign_type.retargeting"-->
          <!--                          class="btn-&#45;&#45;cta btn-blue btn-round">-->
          <!--                    <span>Next</span>-->
          <!--                  </button>-->

          <button @click="$router.push({ 'name': 'triggers' })"
                  class="btn---cta btn-blue btn-round">
            <span>Next</span>
          </button>
        </div>

      </div>
    </div>
  </div>


</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default ({

  components: {},

  data () {
    return {
      exitIntentToggle: true
    }
  },

  created () {
    if (!this.getCampaignAdd.brand_id) {
      this.$router.push({ name: 'create' })
    }
    if (this.getCampaignAdd.background_image_exit_intent) {
      this.exitIntentToggle = false
    }
    /* if (!this.getRetargeting.fetchPixel && this.checkNotClient()) {
                this.getPixels()
                this.setFetchRetargetingStatus(true)
              } */
  },

  computed: {
    ...mapGetters([
      'getCampaignAdd',
      'getCampaignLoaders'
    ])
  },

  methods: {
    ...mapActions([
      'uploadExitIntentBackgroundImage',
      'resetExitIntentBackGroundImage'
    ]),
    toggle (value) {
      this.exitIntentToggle = value
      if (value) {
        this.resetExitIntentBackGroundImage()
      }
    }
  }
})
</script>

<style lang="less" scoped>
.exit-intent-toggle {
  padding: 0 15px;

  .toggle {
    border-color: #45a2ff;
    border-style: solid;
    border-width: 3px 10px;
    width: 40px;
  }

  .content {
    padding: 10px 0px;

    .name {
      .validation_input {
        text-align: center;
      }
    }

    .btn---cta {
      background: white;
      border: 1px solid rgba(28, 34, 51, 0.25);

      span, .fa-upload {
        color: #45a2ff;
      }
    }
  }

  .toggle-background-image {
    margin-left: 190px
  }

  .toggle-background-color {
    margin-left: 42px;
  }

  .url {
    color: rgba(49, 58, 81, 0.6);
    cursor: pointer;
  }

  .active {
    color: black;
  }
}
.recommended_text{
  font-size: 12px !important;
  margin-top:10px !important;
  color: #1f216e !important;
  text-align: center;
}
</style>
